import React, { Component } from 'react'
import styled from 'styled-components'

import divider from '../images/divider.svg'

const Container = styled.div`
  text-align: center;
  /* margin-top: 90px; */
  margin-top: ${p => p.theme.marginToTop};
  margin-bottom: 90px;
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
`

const Main = styled.h1`
  font-size: 38px;
  font-family: Montserrat, sans-serif;
  color: ${p => p.theme.textColorDark};
  font-weight: 600;
  line-height: 1.2;
`

const Sub = styled.h2`
  font-size: 18px;
  font-family: Montserrat, sans-serif;
  color: ${p => p.theme.textColorDark};
  font-weight: 400;
`

const Divider = styled.img`
  margin-top: 16px;
  margin-bottom: 16px;
`

class HeadingMain extends Component {
  render() {
    const { mainHeading, subHeading, subSubHeading } = this.props
    return (
      <Container>
        <Main>{mainHeading}</Main>
        <Divider alt="" src={divider} />
        <Sub>{subHeading}</Sub>
        {subSubHeading && <Sub>{subSubHeading}</Sub>}
      </Container>
    )
  }
}

export default HeadingMain
