import styled from 'styled-components'

const Quote = styled.div`
  padding-left: 29px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 50px;
  margin-bottom: 50px;
  border-left: 7px solid ${p => p.theme.primaryColorLight};
  font-size: 26px;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-style: italic;
  color: ${p => p.theme.textColorDark};
`

export default Quote
