import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import HeadingMain from '../components/HeadingMain'
import Newsletter from '../components/Newsletter'
import SEO from '../components/SEO'

import StyledLink from '../components/styles/StyledLink'
import Quote from '../components/styles/Quote'

const Container = styled.div`
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
  display: flex;
  margin-bottom: 90px;
  :nth-child(2),
  :nth-child(3) {
    margin-bottom: 20px;
  }
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
  }
`

const Left = styled.div`
  position: relative;
  width: 50%;
  padding-right: 80px;
  @media (max-width: 1000px) {
    width: 100%;
    padding-left: ${p => p.theme.paddingOnSides};
    padding-right: ${p => p.theme.paddingOnSides};
    margin-bottom: 30px;
  }
`

const Right = styled.div`
  width: 50%;
  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 32px;
  }
`

const Heading = styled.h1`
  font-size: 30px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: ${p => p.theme.textColorDark};
  margin-bottom: 24px;
`

const Paragraph = styled.p`
  margin-bottom: 26px;
`

const PortraitContainer = styled.div`
  :first-child {
    margin-bottom: 80px;
    @media (max-width: 1000px) {
      margin-bottom: 60px;
    }
    @media (max-width: 800px) {
      margin-bottom: 40px;
    }
  }
`

const Portrait = styled(GatsbyImage)`
  width: 40vw;
  min-width: 100%;
  box-shadow: 0 12px 38px ${p => p.theme.lightGray};
  ${p => p.noShadow && 'box-shadow: none;'}
`

const Copyright = styled.div`
  width: 40vw;
  min-width: 100%;
  text-align: right;
  position: relative;
  color: #b3b3b3;
  font-size: 14px;
  padding-right: 1px;
  padding-top: 1px;
  @media (max-width: 1000px) {
    font-size: 13px;
    padding-right: 8px;
  }
`

const WaterColor = styled(GatsbyImage)`
  position: absolute !important;
  width: 210px;
  opacity: 0.4;
  z-index: -1;
  top: -24px;
  left: -40px;
  @media (max-width: 1000px) {
    width: 170px;
    left: -5px;
    top: -20px;
  }
`

const WatercolorWrapper = styled.div`
  position: relative;
  margin-top: 48px;
`

class YogaUndWein extends Component {
  render() {
    const { yogaImage, yogaImage2, yogaImage3, watercolorImage } = this.props.data
    return (
      <React.Fragment>
        <SEO
          title="Vino Vinyasa Yoga – Yoga & Wein in Gols"
          description="Vino Vinyasa Yoga – Kombination von Körper, Geist und Genuss"
          image="https://www.weingut-goeschl.at/yoga-und-wein.jpg"
        />
        <HeadingMain mainHeading="Vino Vinyasa Yoga" subHeading="Yoga & Wein in Gols" />
        <Container>
          <Left>
            <WaterColor alt="" image={watercolorImage.childImageSharp.gatsbyImageData} />
            <Heading>Vino Vinyasa Yoga – Yoga & Wein in Gols</Heading>
            <Paragraph>
              Yoga ist und kann so vieles – Yoga hilft uns, vom Alltag abzuschalten, zu entspannen, dem Körper etwas
              Gutes zu tun, ihn zu kräftigen, die eigenen Grenzen zu erkennen und zu erweitern. Es hilft uns, unseren
              Körper besser zu verstehen und uns selbst besser kennen zu lernen. Dabei geht es auch ums Los lassen, sich
              selbst nicht zu ernst zu nehmen und vor allem um ein bestimmtes (Körper-)Bewusstsein.
            </Paragraph>
            <Paragraph>
              Daniela, eine der beiden Töchter vom Weingut Göschl, hat ihre Yogalehrerausbildung 2017 in Thailand
              absolviert und unterrichtet seitdem unter{' '}
              <StyledLink
                style={{ whiteSpace: 'nowrap' }}
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/vinovinyasayoga/"
              >
                Vino Vinyasa Yoga
              </StyledLink>{' '}
              nebenberuflich Yoga. Die Yogastunden im Hatha-Vinyasa Stil sind kräftigend aber auch erdend und
              entspannend zugleich. Auf Wunsch gibt es auch Yin (Restorative) Yoga, auf Deutsch oder Englisch.
            </Paragraph>
            <Paragraph>
              Die Yogastunden finden im{' '}
              <StyledLink style={{ whiteSpace: 'nowrap' }}>
                <Link to="/Yogaraum-Gols/">YogaRaum</Link>
              </StyledLink>{' '}
              auf unserem Weingut in Gols statt. Neben den wöchentlichen Kursen gibt es auch verschiedene Pop-Up
              Veranstaltungen. Alle aktuellen Termine sind{' '}
              <StyledLink style={{ whiteSpace: 'nowrap' }}>
                <Link to="/Termine/">hier</Link>
              </StyledLink>{' '}
              zu finden.
            </Paragraph>
          </Left>
          <Right>
            <PortraitContainer>
              <Portrait
                critical={true}
                image={yogaImage.childImageSharp.gatsbyImageData}
                alt="Yoga und Wein - Vino Vinyasa Yoga am Neusiedler See"
              />
              <Copyright>
                <a target="_blank" rel="noopener noreferrer" href="https://www.juliageiter.com/">
                  © Julia Geiter
                </a>
              </Copyright>
            </PortraitContainer>
          </Right>
        </Container>

        <Container>
          <Left>
            <WaterColor alt="" image={watercolorImage.childImageSharp.gatsbyImageData} />
            <Heading>Die Verbindung – Yoga & Wein</Heading>
            <Paragraph>
              Da wir in den Yogastunden auch unsere Sinne schärfen, ist eine Weinverkostung im Anschluss ein wunderbarer
              Ausklang. Entschleunigt und entspannt geht’s nach der Yogaeinheit direkt zu einer kleinen, geführten
              Weinverkostung weiter. Es geht dabei vor allem auch um ein bewusstes Genießen. Das Bewusstsein, das wir
              auch beim Yoga erlernen.
            </Paragraph>
            <Paragraph>
              Auf Anfragen verbinden wir also private Yogaeinheiten auch sehr gerne mit einer Weinverkostung im
              Anschluss. Für private Gruppen ab mind. 2 bis max. 15 Personen.
            </Paragraph>
            <Quote>Für die richtige Balance im Leben!</Quote>
            <Paragraph>
              Gerne fertigen wir auch individuelle Gutscheine an – für private Yogaeinheiten, Yoga & Weinverkostungen
              oder natürlich auch für Weinverkostungen ohne Yogaeinheit oder Wertgutscheine für Weineinkäufe!
            </Paragraph>
          </Left>
          <Right>
            <PortraitContainer>
              <Portrait
                critical={true}
                image={yogaImage3.childImageSharp.gatsbyImageData}
                alt="Yoga und Wein - Vino Vinyasa Yoga am Neusiedler See"
              />
            </PortraitContainer>
          </Right>
        </Container>

        <Container>
          <Left>
            <WaterColor alt="" image={watercolorImage.childImageSharp.gatsbyImageData} />
            <Heading>Yoga & Wine Edition</Heading>
            <Paragraph>
              Passend dazu haben wir auch eine eigene Yoga & Wine Edition kreiert. Drei Naturweine haben sich
              zusammengefügt und ergeben ein wunderbares Ganzes. Der Fokus liegt hier ganz klar an der naturnahen Arbeit
              im Weingarten und auch im Keller. Bewusstes Weglassen und Reduzieren stehen im Vordergrund. Der Wein darf
              sich so entwickeln wie er ist, jeder Jahrgang anders und einzigartig.
            </Paragraph>
            <Paragraph>
              Mehr zu den Weinen unserer limitierten Yoga & Wine Edition findest du{' '}
              <StyledLink style={{ whiteSpace: 'nowrap' }}>
                <Link to="/Weine/#YOGA-AND-WINE-EDITION">hier</Link>
              </StyledLink>
              .
            </Paragraph>

            <WatercolorWrapper>
              <WaterColor alt="" image={watercolorImage.childImageSharp.gatsbyImageData} />
            </WatercolorWrapper>
            <Heading>Yoga & Wein am Weingut Göschl & Töchter</Heading>
            <Paragraph>
              Bei Interesse bitte an Daniela unter{' '}
              <StyledLink style={{ whiteSpace: 'nowrap' }} as="a" href="mailto:daniela@weingut-goeschl.at">
                daniela@weingut-goeschl.at
              </StyledLink>{' '}
              oder{' '}
              <StyledLink style={{ whiteSpace: 'nowrap' }} as="a" href="tel:+436605996979">
                +43 660 599 69 79
              </StyledLink>{' '}
              wenden
            </Paragraph>
            <Paragraph>
              Aktuelles von Vino Vinyasa Yoga gibt’s auf{' '}
              <StyledLink
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/vinovinyasayoga/"
              >
                Facebook
              </StyledLink>{' '}
              +{' '}
              <StyledLink
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/vino_vinyasa_yoga/?hl=de"
              >
                Instagram
              </StyledLink>
            </Paragraph>
          </Left>
          <Right>
            <PortraitContainer>
              <Portrait
                critical={true}
                image={yogaImage2.childImageSharp.gatsbyImageData}
                alt="Yoga und Wein - Vino Vinyasa Yoga am Neusiedler See"
                noShadow
              />
            </PortraitContainer>
          </Right>
        </Container>
        <Newsletter />
      </React.Fragment>
    )
  }
}

export default YogaUndWein

export const query = graphql`
  {
    yogaImage: file(relativePath: { eq: "photos/vino-vinyasa-yoga-am-see-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    yogaImage2: file(relativePath: { eq: "photos/package-yogawines.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    yogaImage3: file(relativePath: { eq: "photos/yoga-und-wein.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    watercolorImage: file(relativePath: { eq: "photos/background-watercolor.png" }) {
      childImageSharp {
        gatsbyImageData(width: 190, quality: 80, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  }
`
